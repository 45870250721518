export default {
  methods: {
    checkRoles(roles) {
      if (
        roles.length == 1 &&
        roles.find((item) => item.slug == "taxpod-salesperson")
      ) {
        this.$router.push({
          name: "HrdcApplication",
          params: {
            modelKey: "hrdc",
          },
          query: {
            viewId: "all",
            tabIndex: 0,
          },
        });

        return false;
      }
    },
  },
};
